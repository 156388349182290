.top-nav {
	width: 100%;
	height: 108px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	justify-content: space-between;
	padding: 10px;
	margin-left:10px;
	margin-right:10px;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
@include for-desktop-down(){
	.top-nav{
		justify-content: space-around;
	}
}
.login-item {
	color: black;
	font-size: 17px;
	font-family: $title-font;
	float: right;
	top: 5px;
	right: 5px;
}
.menu {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menu-item .menu-link,
.menu-item .main-button{
	color: black;
	font-family: $title-font;
	display: block;
}
@include for-desktop-up(){
	#menu-toggle {
		display: none;
	}
	nav {
  	&.primary-navigation {
	
		ul li {
	  	list-style: none;
	  	display: inline-block;
	  	position: relative;
	  	text-decoration: none;
	  	text-align: center;
	  	padding-bottom: 0;
	  	padding-left: 30px;
	  	padding-right: 30px;
		}
		.menu-link {
			margin-top:12px;
		}
	
		li a {
	  	color: black;
	  	font-family: $title-font;
		}
	
		li a:hover {
	  	color: #C4C4C4;
	  	text-decoration: none;
		}
	
		li:hover {
	  	cursor: pointer;
		}
	
		ul li ul {
	  	visibility: hidden;
	  	opacity: 0;
	  	position: absolute;
	  	padding-left: 0;
	  	left: 0;
	  	display: none;
	  	background: white;
		}
	
		ul li:hover > ul,
		ul li ul:hover {
	  	visibility: visible;
	  	opacity: 1;
	  	display: block;
	  	min-width: 200px;
	  	text-align: left;
	  	padding-top: 20px;
	  	box-shadow: 0px 3px 5px -1px #ccc;
		}
	
		ul li ul li {
	  	clear: both;
	  	width: 100%;
	  	text-align: left;
	  	margin-bottom: 20px;
	  	border-style: none;
		}
	
		ul li ul li a:hover {
	  	padding-left: 10px;
	  	border-left: 2px solid $orange;
	  	transition: all 0.3s ease;
		}
  	}
	}
	
	a {
	
		text-decoration: none;
	
		&:hover {
			color: $orange;
		}
 	
	}
 	ul li ul li a { transition: all 0.5s ease; }
}
.navbar-brand {
	height: 72px;
	width: 202px;
	background-size: cover;
	display: inline-block;
}
.main-button {
	display: inline-block;
	background: $std-gradient;
	
	font-family: $title-font;
	color: white !important;
	text-decoration: none;
	font-size: 16.67px;
	
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 12px;
	padding-bottom: 12px;

	border: 0;
	border-radius: 100px;

}
.main-button:hover{
	background: $sf;
}
.sold-out-button{
	display: inline-block;
	background: lightgray;
	
	font-family: $title-font;
	color: black !important;
	text-decoration: none;
	font-size: 16.67px;
	
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 12px;
	padding-bottom: 12px;
	
	border: 1px solid black;
	border-radius: 100px;

}
.sold-out-button:hover{
	text-decoration:none;
	cursor: default;
}
@include for-desktop-down(){
	.notsmall{
		display: none;
	}
	.menu > li {
		margin: auto 1rem;
		overflow: hidden;
	}
	
	.menu-button-container {
	  display: none;
	  height: 100%;
	  width: 30px;
	  cursor: pointer;
	  flex-direction: column;
	  justify-content: center;
	  align-items: center;
	}
	
	#menu-toggle {
		display: none;
	}
	
	.menu-button,
	.menu-button::before,
	.menu-button::after {
		display: block;
		background-color: black;
		position: absolute;
		height: 4px;
		width: 30px;
		transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
		border-radius: 2px;
	}
	
	.menu-button::before {
		content: '';
		margin-top: -8px;
	}
	
	.menu-button::after {
		content: '';
		margin-top: 8px;
	}
	
	#menu-toggle:checked + .menu-button-container .menu-button::before {
		margin-top: 0px;
		transform: rotate(405deg);
	}
	
	#menu-toggle:checked + .menu-button-container .menu-button {
		background: rgba(255, 255, 255, 0);
	}
	
	#menu-toggle:checked + .menu-button-container .menu-button::after {
		margin-top: 0px;
		transform: rotate(-405deg);
	}
	//start low res
		.menu-button-container {
			display: flex;
		}
		.menu {
			position: absolute;
			top: 0;
			margin-top: 108px;
			left: 0;
			flex-direction: column;
			width: 100%;
		}
		@media (min-width: 675px){ //this min width is set to the max width of .menu > li
			.menu{
				left: 50%;
				max-width: 675px;
				margin-left: -337px;
		  	  	box-shadow: 0px 3px 5px -1px #ccc;
			}
		}
		#menu-toggle ~ .menu li {
			height: 0;
			margin: 0;
			padding: 0;
			border: 0;
			transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
		}
		#menu-toggle:checked ~ .menu li {
			border-top: 1px solid rgb(241,244,248);
			padding-top: 8px;
			padding-left: 24px;
			padding-right: 24px;
			padding-bottom: 46px;
			transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
			background: white;
			z-index: 100;
		}
		.menu > li {
			width: 100%;
		}
	//end low res
	.menu-link:after{
		display: block;
		content: '';
		line-height: 100%;
		transform: scaleX(0);
		transition: transform 250ms ease-in-out;
		transform-origin: 100% 50%;
	}
	.menu-link:hover:after,
	.menu-link:focus:after {
		transform: scaleX(1);
		transform-origin: 0% 50%;
	}
	.menu-link:after{
		border-bottom: solid 3px $peach;
	}
	.menu-item .menu-link,
	.menu-item .main-button{
		color: black;
		font-size: 17px;
		font-family: $title-font;
		display: block;
		
	}
	.main-button{
		margin-bottom: 8px;
	}
	.menu-link{
		padding-top: 8px;
		padding-bottom: 8px;
		text-decoration: none !important;
	}
	.menu-subitem{
		display: block;
		height: 30px;
	}
	.dark {
		.menu-item .menu-link,
		.menu-item .main-button{
			color: white;
		}
		.menu-button,
		.menu-button::before,
		.menu-button::after {
			display: block;
			background-color: white;
		}
		#menu-toggle:checked ~ .menu li {
			background: $dblue;
		}
	}
	.active:after {
		transform: scaleX(1) !important;
	}
	.top-nav.dark {
		background-color: $dblue;
	}
	.button{
		color: black;
	}
}
//NoDisplays at the end
@include for-desktop-up(){
	.notbig{
		display: none !important;
	}
}
@include for-desktop-down(){
	.notsmall{
		display: none !important;
	}
}