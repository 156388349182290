//includes and buttons

@include for-mobile-only(){
	.navbar-brand {
		height: 54px;
		width: 152px;
	}
	.main-button {
		font-size: 13px;
	}
}
a:hover{
	color: #C4C4C4;
}
.white a:hover {
	text-decoration: underline;
}

.hollow-button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 110px;
	
	font-family: $title-font;
	color: white !important;
	text-decoration: none;
	font-size: 16.67px;
	
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 12px;
	padding-bottom: 12px;

	border: 1px solid white;
	border-radius: 100px;

}
.hollow-button:hover {
	background: white;
	color: $sf !important;
}
.footer-section{
	font-family: $title-font;
	a {
		color:black;
	}
	li {
		margin-bottom: 30px;
	}
	.eyebrow{
		color: rgb(135, 135, 135);
	}
}

//Section
section {
	width: 100%;
	padding-top: 65px;
	padding-bottom: 65px;
}
.banner {
	background-color: rgb(255, 248, 197); //Github alert color
	padding-top: 10px;
	padding-bottom: 10px;
}
.clear {
	background-color: white;
}
.tint {
	background-color: $tint;
}
.gradient {
	background: $std-gradient;
}
//fonts

h1 {
	font-size: 50px;
	font-family: $title-font;
	line-height: 65px;
	font-weight: 500;
}
h2 {
	font-size: 24px;
	font-family: $title-font;
	line-height: 30px;
	font-weight: 500;
}
h3 {
	font-size: 18px;
	font-family: $title-font;
	line-height: 24px;
	font-weight: 500;
}
@include for-desktop-up(){
	h1 {
		font-size: 76px;
		line-height: 85px;
	}
	h2 {
		font-size: 45px;
		font-family: $title-font;
		line-height: 54px;
		font-weight: 500;
	}
	h3 {
		font-size: 30px;
		font-family: $title-font;
		line-height: 36px;
		font-weight: 500;
	}
}
.body {
	font-family: $body-font;
	font-size: 17px;
	line-height: 23px;
	font-weight: 400;
}
.eyebrow {
	font-family: $title-font;
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	color: $orange;
}
ul> .eyebrow {
	color: $purple;
}
.bottom-rule {
	border-bottom: 2.25px solid $tint;
}
.tint {
	.bottom-rule {
		border-bottom: 2.25px solid white;
	}
}
.big-text {
	font-family: $title-font;
	font-size: 100px;
	line-height: 120px;
	margin-bottom: 0;
	margin-top: 20px;
}
.stats-h3 {
	width: 150px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	
	margin-top: 10px;
	margin-bottom: 5px;
}
.spec-item-right{
	font-family: $body-font;
}
.big-claim {
	font-family: $title-font;
	font-size: 60px;
	line-height: 73px;
	margin-bottom: 20px;
}
.benefit-header{
	height: 72px;
}
.benefit-header.short {
	height: auto;
}
@include for-mobile-only(){
	.benefit-header{
		height: auto;
	}
}
.center-text {
	text-align: center;
}
.right-text {
	text-align: right;
}
.faux-link{
	color: rgb(0, 0, 238);
}
.faux-link:hover{
	cursor: pointer;
}
.white {
	color: white;
	a {
		color: white;
	}
}
a{
	text-decoration: none;
}
a:hover{
	text-decoration: underline;
}
//list
ul{
	padding-left: 0;
}
ol {
	padding-left: 0
}
li{
	list-style-type: none;
	padding-bottom: 25px;
}
.gen-list{
	padding-left: revert;
}
.gen-list-item{
	list-style-type: initial;
	padding-bottom: revert;
}
.ordered{
	list-style-type: decimal;
}
.footer{
	padding-bottom: 5px;
}
.spec-item-right{
	line-height: 24px;
}
@include for-mobile-only(){
	.eyebrow.spec-item-left{
		font-size: 15px;
	}
}
 //images
 .hero-image-square {
	 width: 476px;
	 height: 489px;
	 border-radius: 15px;
 }
 @include for-mobile-only(){
	 .hero-image-square {
		width: 357px;
		height: 367px;
	}
 }
img{
	 margin-left: auto;
	 margin-right: auto;
	 display: block;
 }
 .card-icon{
	height: 233px;
	width: 317px;
	border-radius: 20px;
 }
 .icon {
		height: 163px;
		width: 138px;
 }
 @include for-mobile-only(){
	 .icon {
		 height: 122px;
		 width: 103px;
	 }
	 .card-icon {
		 height: 122px;
		 width: 166px;
	 }
 }
 @include for-desktop-up(){
 	.quickstart-image{
		margin-top: 60px;	
	}
 }
 .roundedwideimage {
	 height: 134px;
	 width: 268px;
	 border-radius: 20px;
 }
//flexbox
.container{
	max-width: 1400px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-left: auto;
	margin-right: auto;
}
.container-narrow{
	max-width: 766px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-left: auto;
	margin-right: auto;
}
.container-left{
	max-width: 1400px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-left: auto;
	margin-right: auto;
}
@include for-desktop-down(){
	.container{
	padding-left: 10px;
	padding-right: 10px;
}
	.container-narrow{
		padding-left: 10px;
		padding-right: 10px;
	}
}
.spec-detail-container .container {
	align-items: flex-start;
}
.two-item{
	width: 595px;
}
.three-item {
	width: 350px;
}
.four-item{
	width: 275px;
}
.six-item{
	width: 160px;
	display: inline;
	img {
		display: inline;
		width: 160px;
	}
}
.buy-now-button{
	width: 160px;
}
@include for-desktop-down(){
	.above{
		order: -1;		
	}
}
@include for-mobile-only(){
	.four-item{
		width: 215px;
	}
}
//cards
.cardlink{
	color: initial;
}
.card{
	//border: 1px solid black;
	border-radius: 20px;
	padding-top: 35px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 15px;
	background: white;
	box-shadow: 0px 3px 5px -1px #ccc;
}
.card:hover{
	background: $purple;
	color: white; 
}
.card{
	h2{
		line-height: 50px;
	}
}
.cardlink:hover {
	text-decoration: none;
	color: initial;
}
.two-card{
	max-width: 450px;
}
@media (max-width: 995px){
	.cardlink:first-of-type{
		margin-top: 0;
	}
	.cardlink{
		margin-top: 40px;
	}
}
//info box
@include for-desktop-up(){
	.info-headline{
		margin-top: 10px;
		margin-bottom: 15px;
	}
	.info-body{
		margin-top: 0;
	}
}
//specifics
#hero-section{
	margin-top: 0px;
	padding-top:0px;
}
#hero-container{
	align-items: center;
}
#hero-headline{
	margin-bottom:40px;
}
#hero-body{
	margin-bottom:25px;
}
@include for-desktop-up(){
	#hero-body{
		width: 500px;
	}
}
#hero-image{
	margin-top:80px;
}
#danny-hero{
	margin-top: 30px;
}
#onepager-section{
	padding-top:20px;
	padding-bottom:20px;
}
#carousel{
	padding: 0;
	max-width: 1400px;
	margin-left:auto;
	margin-right: auto;
}
.carousel-item{
	width:100%;
}
@include for-desktop-up(){
	.carousel-item{
		max-width: 1400px;
	}
}
@include for-tiny-only(){
	#specs {
		display: none;
	}
}
#specs-list-right{
	margin-top:16px;
}
@include for-desktop-up(){
	#pricing-info-box{
		margin-top: 50px;
	}
	#credit-info-box{
		margin-top: 75px;
	}
	#claim-button{
		margin-top: 30px;
		margin-bottom: 60px;
	}
}
#footer-logo{
	width: 300px;
	margin-left: 0;
}
@include for-desktop-down(){
	#footer-logo{
		margin-left: auto;
		margin-bottom: 50px;
	}
}
#privacy-link{
	margin-left:55px;
}
//Form
.nodisplay{
	display:none;
}
input{
	border: none;
	height: 30px;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
}
#f-send {
	width: auto;
	height: auto;
	cursor: pointer;
}
#f-Send:hover{
	cursor: pointer;
}
.bold { font-weight: 900;}