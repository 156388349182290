body{
	color: black;
	background-color: white;
}

//Fonts
$body-font: 'Lato', sans-serif;
$title-font: 'futura-pt', 'Rubik', 'Lato', sans-serif;

// # Colors
// ## By Name
$white: #FFF;
$black: #000;
$dblue: #323d53;
$brown: #b86d62;
$peach:	#d57a66;
$orange: #d4825b;
$purple: #7e4d64;
$blush: #ac627d;
$pink: #d47486;
$lavender: #766d88;
$sf: #775063;
$pale: #dbdac8;
$black: #1E1E1E;
$tint: #FBF5F5;

//Gradients
$std-gradient: linear-gradient(to bottom right, rgb(126, 77, 100) 20%, rgb(236, 136, 114) 80%);
$geo-gradient: linear-gradient(to bottom left, #7E4D64 0%, #AD616D 30%, #D77C6C 50%, #F39F67 90%, #FFC964 100%);
$tbl-gradient: radial-gradient(#cccac7,#c5c2be);

@mixin for-tiny-only {
  @media (max-width: 490px) { @content; }
}
@mixin for-mobile-only {
  @media (max-width: 649px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 650px) { @content; }
}
@mixin for-tablet-landscape-down {
  @media (max-width: 899px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-down {
  @media (max-width: 1229px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1230px) { @content; }
}
@mixin for-big-desktop-down {
  @media (max-width: 1799px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}